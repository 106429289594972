<template>
  <section class="descr-list">
    <div class="container">
      <div class="descr-list__inner">
        <p class="descr-list__text">
          Компания «ТЭЦ» предлагает водителям грузовых автотранспортных средств услуги технического
          обслуживания, комплексную диагностику и ремонт автомобилей. В центре испытаний «Технологический
          Экспертный Центр» представлен следующий сервис:<br>
        </p>
        <ul class="descr-list__list">
          <li class="descr-list__item">
            Испытание процентного состава газообразных веществ в газовом выхлопе.
          </li>
          <li class="descr-list__item">
            Соответствие нормам свободного хода руля рулевого механизма.
          </li>
          <li class="descr-list__item">
            Работа автомобильной светотехники
          </li>
          <li class="descr-list__item">
            Соответствие чистоты стекла и его светопропускания установленным стандартам
          </li>
          <li class="descr-list__item">
            Наличие и полная комплектация дорожной аптечки, знака экстренной остановки и<br>
            противопожарного баллона.
          </li>
          <li class="descr-list__item">
            Исправность и эффективность тормозов, а также распределение нагрузки на оси во время<br>
            торможения.
          </li>
          <li class="descr-list__item">
            Состояние тормоза для стоянок и его работоспособность, а также ряд других параметров.
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'HeavyCarList'
}
</script>
